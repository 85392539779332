import { createSlice } from "@reduxjs/toolkit";
import {
  changePlan,
  expiredSubscriptionWithTwoMinutes,
  getSubscription,
  getSubscriptionUsers,
  pausedSubscription,
  startFreeTrial,
  subscriptionAddUser,
  unSubscribeSubscription,
  unSubscribeTrial,
  updateSubscription,
} from "./subscriptionService";

const initialRolesState = {
  subscriptionData: null,
  startFreeTrialLoading: false,
  startFreeTrialError: null,
  getSubscriptionLoading: false,
  getSubscriptionError: null,
  updateSubscriptionLoading: false,
  updateSubscriptionError: null,
  unSubscribeTrialLoading: false,
  unSubscribeTrialError: null,
  changePlanLoading: false,
  changePlanError: null,
  unSubscribeSubscriptionLoading: false,
  unSubscribeSubscriptionError: null,
  addSubscriptionUserLoading: false,
  addSubscriptionUserError: null,
  subscriptionAddNewUser: null,
  pausedSubscriptionLoading: false,
  pausedSubscriptionError: null,
  subscriptionUsers: [],
  getSubscriptionUsersLoading: false,
  getSubscriptionUsersError: null,
  expiredSubscriptionWithTwoMenutsLoading: false,
  expiredSubscriptionWithTwoMenutsError: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialRolesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startFreeTrial.pending, (state) => {
        state.startFreeTrialLoading = true;
        state.startFreeTrialError = null;
      })
      .addCase(startFreeTrial.fulfilled, (state, action) => {
        state.startFreeTrialLoading = false;
        state.subscriptionData = action.payload;
        state.startFreeTrialError = null;
      })
      .addCase(startFreeTrial.rejected, (state, action) => {
        state.startFreeTrialLoading = false;
        state.startFreeTrialError = action.error.message;
      })
      .addCase(getSubscription.pending, (state) => {
        state.getSubscriptionLoading = true;
        state.getSubscriptionError = null;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.getSubscriptionLoading = false;
        state.subscriptionData = action.payload;
        state.getSubscriptionError = null;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.getSubscriptionLoading = false;
        state.startFreeTrialError = action.error.message;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.updateSubscriptionLoading = true;
        state.updateSubscriptionError = null;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.updateSubscriptionLoading = false;
        state.subscriptionData = action.payload;
        state.updateSubscriptionError = null;
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.updateSubscriptionLoading = false;
        state.updateSubscriptionError = action.error.message;
      })
      .addCase(unSubscribeTrial.pending, (state) => {
        state.unSubscribeTrialLoading = true;
        state.unSubscribeTrialError = null;
      })
      .addCase(unSubscribeTrial.fulfilled, (state, action) => {
        state.unSubscribeTrialLoading = false;
        state.subscriptionData = action.payload;
        state.unSubscribeTrialError = null;
      })
      .addCase(unSubscribeTrial.rejected, (state, action) => {
        state.unSubscribeTrialLoading = false;
        state.unSubscribeTrialError = action.error.message;
      })
      .addCase(changePlan.pending, (state) => {
        state.changePlanLoading = true;
        state.changePlanError = null;
      })
      .addCase(changePlan.fulfilled, (state, action) => {
        state.changePlanLoading = false;
        state.subscriptionData = action.payload;
        state.changePlanError = null;
      })
      .addCase(changePlan.rejected, (state, action) => {
        state.changePlanLoading = false;
        state.changePlanError = action.error.message;
      })
      .addCase(unSubscribeSubscription.pending, (state) => {
        state.unSubscribeSubscriptionLoading = true;
        state.unSubscribeSubscriptionError = null;
      })
      .addCase(unSubscribeSubscription.fulfilled, (state, action) => {
        state.unSubscribeSubscriptionLoading = false;
        state.subscriptionData = action.payload;
        state.unSubscribeSubscriptionError = null;
      })
      .addCase(unSubscribeSubscription.rejected, (state, action) => {
        state.unSubscribeSubscriptionLoading = false;
        state.unSubscribeSubscriptionError = action.error.message;
      })
      .addCase(subscriptionAddUser.pending, (state) => {
        state.addSubscriptionUserLoading = true;
        state.addSubscriptionUserError = null;
      })
      .addCase(subscriptionAddUser.fulfilled, (state, action) => {
        state.addSubscriptionUserLoading = false;
        state.subscriptionAddNewUser = action.payload;
        state.addSubscriptionUserError = null;
      })
      .addCase(subscriptionAddUser.rejected, (state, action) => {
        state.addSubscriptionUserLoading = false;
        state.addSubscriptionUserError = action.error.message;
      })
      .addCase(pausedSubscription.pending, (state) => {
        state.pausedSubscriptionLoading = true;
        state.pausedSubscriptionError = null;
      })
      .addCase(pausedSubscription.fulfilled, (state, action) => {
        state.pausedSubscriptionLoading = false;
        state.subscriptionData = action.payload;
        state.pausedSubscriptionError = null;
      })
      .addCase(pausedSubscription.rejected, (state, action) => {
        state.pausedSubscriptionLoading = false;
        state.pausedSubscriptionError = action.error.message;
      })
      .addCase(getSubscriptionUsers.pending, (state) => {
        state.getSubscriptionUsersLoading = true;
        state.getSubscriptionUsersError = null;
      })
      .addCase(getSubscriptionUsers.fulfilled, (state, action) => {
        state.getSubscriptionUsersLoading = false;
        state.subscriptionUsers = action.payload;
        state.getSubscriptionUsersError = null;
      })
      .addCase(getSubscriptionUsers.rejected, (state, action) => {
        state.getSubscriptionUsersLoading = false;
        state.getSubscriptionUsersError = action.error.message;
      })
      .addCase(expiredSubscriptionWithTwoMinutes.pending, (state) => {
        state.expiredSubscriptionWithTwoMenutsLoading = true;
        state.expiredSubscriptionWithTwoMenutsError = null;
      })
      .addCase(expiredSubscriptionWithTwoMinutes.fulfilled, (state, action) => {
        state.expiredSubscriptionWithTwoMenutsLoading = false;
        state.subscriptionData = action.payload;
        state.expiredSubscriptionWithTwoMenutsError = null;
      })
      .addCase(expiredSubscriptionWithTwoMinutes.rejected, (state, action) => {
        state.expiredSubscriptionWithTwoMenutsLoading = false;
        state.expiredSubscriptionWithTwoMenutsError = action.error.message;
      });
  },
});
