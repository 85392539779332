import React, { useState, useEffect, useRef } from "react";
import DateRangePicker from "../../../_metronic/_partials/controls/date-picker/DateRangePicker";
import Select, { components } from "react-select";
import RetailStoreIcon from "../../../assets/img/store-icon.svg";
import UserProfileIcon from "../../../assets/img/person-icon..svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TimeRangePicker from "../../../_metronic/_partials/controls/date-picker/TimeRangePicker";
import {
  setStartDate as setStartingDate,
  setEndDate as setEndingDate,
  setDatesRangeLabel,
} from "../../../../src/_redux/analytics/analyticsSlice";
import {
  getGrossSalesChartData,
  getTableData,
  getAllStores,
  getAllUsers,
} from "../../../_redux/analytics/analyticsService";

const SideCard = (props) => {
  const {
    handleSelectStores,
    handleSelectUsers,
    page,
    limit,
    tableType,
    setStartDate,
    setEndDate,
    showTimeRange,
    startDate,
    endDate,
    onTimeRangeChange,
    timeRange,
    selectedTimeRange,
    setSelectedTimeRange,
    shouldSetDailyTimeRange,
  } = props;

  const dispatch = useDispatch();

  const [enabledOptions, setEnabledOptions] = useState([]);
  const last30DaysStart = moment().subtract(29, "days");
  const today = moment();
  const [allStores, setAllStores] = useState("all");
  const [allUsers, setAllUsers] = useState("all");
  const [sortOrder, setSortOrder] = useState("asc");
  const [timeZone, setTimeZone] = useState("Asia/Riyadh");
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { stores, users } = useSelector((state) => state.analytics);

  // Separate states for controlling the open/close status of each dropdown
  const [storesDropdownOpen, setStoresDropdownOpen] = useState(false);
  const [usersDropdownOpen, setUsersDropdownOpen] = useState(false);

  // Refs to detect clicks outside the dropdowns
  const storesDropdownRef = useRef(null);
  const usersDropdownRef = useRef(null);

  useEffect(() => {
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  useEffect(() => {
    dispatch(getAllStores());
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        storesDropdownRef.current &&
        !storesDropdownRef.current.contains(event.target)
      ) {
        setStoresDropdownOpen(false);
      }
      if (
        usersDropdownRef.current &&
        !usersDropdownRef.current.contains(event.target)
      ) {
        setUsersDropdownOpen(false);
      }
    };

    // Capture the event at the start of the event propagation
    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  const handleTimeRangeSelect = async (selectedTimeRange) => {
    setSelectedTimeRange(selectedTimeRange.value);

    // Format the dates
    const formattedStartDate = startDate.format("M-DD-yy");
    const formattedEndDate = endDate.format("M-DD-yy");

    // Dispatch an action to fetch the chart data based on the selected time range
    await dispatch(
      getGrossSalesChartData({
        allStores,
        allUsers,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        timeRange: selectedTimeRange,
        timeZone,
      })
    );
  };

  const handleTimeRangeChange = (timeRanges) => {
    setEnabledOptions(timeRanges);
  };

  const isDisabled = (option) => {
    return !enabledOptions.includes(option);
  };

  const storeOptions = [
    { value: "all", label: "All Stores" },
    ...stores.map((store) => ({ value: store._id, label: store.name })),
  ];

  const userOptions = [
    { value: "all", label: "All Users" },
    ...users.map((user) => ({
      value: user._id,
      label: `${user.name.first} ${user.name.last}`,
    })),
  ];

  const handleStoresChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const wasAllPreviouslySelected = selectedStores.some(
      (option) => option.value === "all"
    );

    if (isAllSelected && !wasAllPreviouslySelected) {
      setSelectedStores([...storeOptions]);
      handleSelectStores(storeOptions.slice(1).map((store) => store.value));
    } else if (!isAllSelected && wasAllPreviouslySelected) {
      setSelectedStores([]);
      handleSelectStores("all");
    } else {
      const filteredStores = selected.filter(
        (option) => option.value !== "all"
      );

      if (isAllSelected && selected.length < storeOptions.length) {
        setSelectedStores([...filteredStores]);
        handleSelectStores(filteredStores.map((store) => store.value));
      } else if (
        !isAllSelected &&
        selected.length === storeOptions.length - 1
      ) {
        setSelectedStores([...storeOptions]);
        handleSelectStores(storeOptions.slice(1).map((store) => store.value));
      } else {
        setSelectedStores([...selected]);
        handleSelectStores(selected.map((store) => store.value));
      }

      if (filteredStores.length === 0) {
        setSelectedStores([]);
        // setSelectedStores([...storeOptions]);
        handleSelectStores("all");
      }
    }
  };

  const handleUsersChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const wasAllPreviouslySelected = selectedUsers.some(
      (option) => option.value === "all"
    );

    if (isAllSelected && !wasAllPreviouslySelected) {
      setSelectedUsers([...userOptions]);
      handleSelectUsers(userOptions.slice(1).map((user) => user.value));
    } else if (!isAllSelected && wasAllPreviouslySelected) {
      setSelectedUsers([]);
      handleSelectUsers("all");
    } else {
      const filteredUsers = selected.filter((option) => option.value !== "all");
      if (isAllSelected && selected.length < userOptions.length) {
        setSelectedUsers([...filteredUsers]);
        handleSelectUsers(filteredUsers.map((user) => user.value));
      } else if (!isAllSelected && selected.length === userOptions.length - 1) {
        setSelectedUsers([...userOptions]);
        handleSelectUsers(userOptions.slice(1).map((user) => user.value));
      } else {
        setSelectedUsers([...selected]);
        handleSelectUsers(selected.map((user) => user.value));
      }
      if (filteredUsers.length === 0) {
        setSelectedUsers([]);
        // setSelectedusers([...userOptions]);
        handleSelectUsers("all");
      }
    }
    
  };

  const Option = (props) => {
    const isAllOption =
      props.label === "All Stores" || props.label === "All Users";

    return (
      <components.Option {...props}>
        <span className="menu-item d-flex align-items-center">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label
            className={`ml-2 mt-2 ${isAllOption ? "font-weight-bold" : ""}`}
          >
            {props.label}
          </label>
        </span>
      </components.Option>
    );
  };
  

  const MultiValue = (props) => null;

  const ValueContainer = ({ children, ...props }) => {
    const selectedValues = props.getValue();
    let displayText = "";

    if (selectedValues.length === 1) {
      displayText = selectedValues[0].label;
    } else if (selectedValues?.length === storeOptions?.length) {
      displayText = "All Stores";
    } else if (selectedValues?.length === userOptions?.length) {
      displayText = "All Users";
    } else if (selectedValues.length > 1) {
      displayText = `${selectedValues.length} ${
        props.selectProps.placeholder === "Select Stores" ? "Stores" : "Users"
      }`;
    }

    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue && (
          <div className="custom-value-container">{displayText}</div>
        )}
        {children}
      </components.ValueContainer>
    );
  };

  const SearchableSelect = ({
    options,
    onChange,
    value,
    placeholder,
    icon,
    classNamePrefix,
    menuIsOpen,
    onMenuOpen,
    onMenuClose,
    dropdownRef,
  }) => (
    <div ref={dropdownRef}>
      <Select
        isMulti
        options={options}
        components={{ Option, MultiValue, ValueContainer, Control }}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        isSearchable
        classNamePrefix={classNamePrefix}
        icon={icon}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </div>
  );

  const Control = ({ children, ...props }) => (
    <components.Control
      {...props}
      className={`${props.selectProps.classNamePrefix}-control`}
    >
      <img src={props.selectProps.icon} alt="" className="select-icon" />{" "}
      {children}
    </components.Control>
  );

  const resetFilters = async () => {
    // Reset the selected stores and users state

    setSelectedStores([...storeOptions]);
    setSelectedUsers([...userOptions]);
    setStartDate(last30DaysStart);
    setEndDate(today);
    dispatch(setDatesRangeLabel("Last 30 Days"))
    if (shouldSetDailyTimeRange) {
      setSelectedTimeRange("daily");
    }

    const formattedStartDate = last30DaysStart.format("M-DD-yy");
    const formattedEndDate = today.format("M-DD-yy");

    await dispatch(
      getTableData({
        tableType,
        page,
        limit,
        allStores: "all",
        allUsers: "all",
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        sortOrder,
        groupBy: selectedTimeRange,
        timeZone,
      })
    );
  };

  return (
    <div className="card side-menu col-md-2">
      <span
        role="button"
        className="reset-btn text-right text-primary"
        onClick={resetFilters}
      >
        RESET ALL
      </span>
      <div className="mb-3 mt-3">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectedTimeRange={selectedTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
          handleTimeRangeChange={handleTimeRangeChange}
          handleTimeRangeSelect={handleTimeRangeSelect}
          shouldSetDailyTimeRange={shouldSetDailyTimeRange}
        />
      </div>
      <div className="store-icon mb-3">
        <SearchableSelect
          options={storeOptions}
          onChange={handleStoresChange}
          value={selectedStores}
          placeholder="Select Stores"
          icon={RetailStoreIcon}
          classNamePrefix="store"
          menuIsOpen={storesDropdownOpen}
          onMenuOpen={() => {
            setStoresDropdownOpen(true);
            setUsersDropdownOpen(false);
          }}
          onMenuClose={() => setStoresDropdownOpen(false)}
          dropdownRef={storesDropdownRef} // Pass the ref
        />
      </div>
      <div className="user-icon mb-3">
        <SearchableSelect
          options={userOptions}
          onChange={handleUsersChange}
          value={selectedUsers}
          placeholder="Select Users"
          icon={UserProfileIcon}
          classNamePrefix="user"
          menuIsOpen={usersDropdownOpen}
          onMenuOpen={() => {
            setUsersDropdownOpen(true);
            setStoresDropdownOpen(false);
          }}
          onMenuClose={() => setUsersDropdownOpen(false)}
          dropdownRef={usersDropdownRef}
        />
      </div>
      {showTimeRange && (
        <div className="mb-3">
          <Select
            value={{
              value: selectedTimeRange,
              label:
                selectedTimeRange.charAt(0).toUpperCase() +
                selectedTimeRange.slice(1),
            }}
            onChange={handleTimeRangeSelect}
            options={[
              {
                value: "hourly",
                label: "Hourly",
                isDisabled: isDisabled("hourly"),
              },
              {
                value: "daily",
                label: "Daily",
                isDisabled: isDisabled("daily"),
              },
              {
                value: "weekly",
                label: "Weekly",
                isDisabled: isDisabled("weekly"),
              },
              {
                value: "monthly",
                label: "Monthly",
                isDisabled: isDisabled("monthly"),
              },
              {
                value: "quarterly",
                label: "Quarterly",
                isDisabled: isDisabled("quarterly"),
              },
              {
                value: "yearly",
                label: "Yearly",
                isDisabled: isDisabled("yearly"),
              },
            ]}
            isOptionDisabled={(option) => option.isDisabled}
            placeholder="Select Time Range"
            classNamePrefix="time-range"
          />
        </div>
      )}
      {timeRange && <TimeRangePicker onTimeRangeChange={onTimeRangeChange} />}
    </div>
  );
};

export default SideCard;
