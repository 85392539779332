import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import objectPath from "object-path";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { i18n } from "../../../../app/pages/private/languageSelector";

export function Header() {
  const { language } = useSelector((state) => state.auth);
  const { startDate, endDate, datesRangeLabel } = useSelector(
    (state) => state.analytics
  );
  // console.log("start",  startDate)
  // console.log("endDate", endDate)
  // console.log("reduxRangeLabel",datesRangeLabel)

  const last30DaysStart = moment().subtract(29, "days");
  const today = moment();

  const uiService = useHtmlClassService();
  const path = window.location.href.toLowerCase().includes("dashboard")
    ? i18n("LandingPage.DASHBOARD")
    : window.location.href.toLowerCase().includes("customers")
    ? i18n("LandingPage.CUSTOMER")
    : window.location.href.toLowerCase().includes("products")
    ? i18n("LandingPage.MENU")
    : window.location.href.toLowerCase().includes("invoices")
    ? i18n("LandingPage.INVOICES")
    : window.location.href.toLowerCase().includes("company")
    ? i18n("LandingPage.COMPANY")
    : window.location.href.toLowerCase().includes("reports")
    ? i18n("LandingPage.REPORTS")
    : "";

  const currentPath = window.location.pathname.toLowerCase();
  const isAnalyticsSalesSummary = [
    "/analytics/sales-summary",
    "/sales-by-product",
    "/sales-by-category",
    "/sales-by-user",
    "/sales-by-payment-channel",
    "/sales-by-time",
    "/tax-report",
    "/discount-report",
  ].includes(currentPath);

  const layoutProps = {
    headerClasses: uiService.getClasses("header", true),
    headerAttributes: uiService.getAttributes("header"),
    headerContainerClasses: uiService.getClasses("header_container", true),
    menuHeaderDisplay: objectPath.get(
      uiService.config,
      "header.menu.self.display"
    ),
  };

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header  ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={`header-inner ${layoutProps.headerContainerClasses}`}
          style={{ marginRight: language === "arabic" && "300px" }}
        >
          {language === "arabic" ? null : <h3>{path}</h3>}
          {/* <AnimateLoading /> */}
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && language === "english" && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {isAnalyticsSalesSummary && (
        <div className="date-header">
          <span>
            {`${datesRangeLabel} (${moment(startDate).format(
              "MMM D, YYYY"
            )} - ${moment(endDate).format("MMM D, YYYY")})`}
          </span>
        </div>
      )}
      {/*end::Header*/}
    </>
  );
}
