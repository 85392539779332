import React, { useState, useEffect } from "react";
import { DateRangePicker as RBDaterangepicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CalanderIcon from "../../../../assets/img/calander-icon.svg";
import { useDispatch } from "react-redux";
import { setStartDate, setEndDate, setDatesRangeLabel  } from "../../../../_redux/analytics/analyticsSlice"; // Import actions
import toast from "react-hot-toast";

const DateRangePicker = ({
  setStartDate: setPropStartDate,
  setEndDate: setPropEndDate,
  startDate: propStartDate,
  endDate: propEndDate,
  handleTimeRangeChange,
  setSelectedTimeRange,
  selectedTimeRange,
  shouldSetDailyTimeRange,
}) => {
  const dispatch = useDispatch(); // Initialize dispatch
  const today = moment();

  const getInitialDates = () => {
    const savedStartDate = localStorage.getItem("startDate");
    const savedEndDate = localStorage.getItem("endDate");
    const savedRangeLabel = localStorage.getItem("rangeLabel");
    const savedTimeRange = localStorage.getItem("selectedTimeRange");

    if (savedStartDate && savedEndDate && savedRangeLabel && savedTimeRange) {
      return {
        startDate: moment(savedStartDate),
        endDate: moment(savedEndDate),
        rangeLabel: savedRangeLabel,
        timeRange: JSON.parse(savedTimeRange),
      };
    }

    if (propStartDate && propEndDate) {
      return {
        startDate: propStartDate,
        endDate: propEndDate,
        rangeLabel: "",
        timeRange: selectedTimeRange || [],
      };
    }

    return {
      startDate: propStartDate || moment().subtract(29, "days"),
      endDate: propEndDate || today,
      rangeLabel: "Last 30 Days",
      timeRange: [],
    };
  };

  const [startDate, setStartDateState] = useState(getInitialDates().startDate);
  const [endDate, setEndDateState] = useState(getInitialDates().endDate);
  const [rangeLabel, setRangeLabel] = useState(getInitialDates().rangeLabel);
  const [timeRange, setTimeRange] = useState(getInitialDates().timeRange);

  useEffect(() => {
    if (propStartDate && propEndDate) {
      setStartDateState(propStartDate);
      setEndDateState(propEndDate);
    }
  }, [propStartDate, propEndDate]);

  useEffect(() => {
    setPropStartDate(startDate);
    setPropEndDate(endDate);
    handleTimeRangeChange(timeRange);
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));    
  }, [startDate, endDate, timeRange]);

  const handleApply = (event, picker) => {
    let { startDate, endDate } = picker;

    const daysDiff = endDate.diff(startDate, "days");

    // Check if the difference exceeds 366 days
    if (daysDiff > 365) {
      toast.error("You can't select a date range longer than 1 year.");
      return; // Exit early if the range exceeds 365 days
    }

    setPropStartDate(startDate);
    setPropEndDate(endDate);
    setStartDateState(startDate);
    setEndDateState(endDate);
    setRangeLabel(picker.chosenLabel);
    dispatch(setDatesRangeLabel(picker.chosenLabel));
    localStorage.setItem("startDate", startDate.toISOString());
    localStorage.setItem("endDate", endDate.toISOString());
    localStorage.setItem("rangeLabel", picker.chosenLabel);

    // dispatch(setDatesRangeLabel(picker.chosenLabel));

    let timeRanges = [];

    if (startDate.isSame(endDate, "day")) {
        timeRanges = ["hourly"];
        if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("hourly");
        }
    } else if (daysDiff >= 365) {
        timeRanges = ["daily", "weekly", "monthly", "quarterly", "yearly"];
        if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("daily");
        }
    } else if (daysDiff > 90) {
        timeRanges = ["daily", "weekly", "monthly", "quarterly"];
        if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("daily");
        }
    } else {
        switch (picker.chosenLabel) {
            case "Today":
            case "Yesterday":
                timeRanges = ["hourly"];
                if (shouldSetDailyTimeRange) {
                    setSelectedTimeRange("hourly");
                }
                break;
            case "This Week":
            case "Last Week":
            case "Last 7 Days":
                timeRanges = ["daily", "weekly"];
                if (shouldSetDailyTimeRange) {
                    setSelectedTimeRange("daily");
                }
                break;
            case "This Month":
            case "Last Month":
            case "Last 30 Days":
                timeRanges = ["daily", "weekly", "monthly"];
                if (shouldSetDailyTimeRange) {
                    setSelectedTimeRange("daily");
                }
                break;
            default:
                timeRanges = ["daily", "weekly", "monthly"];
                if (shouldSetDailyTimeRange) {
                    setSelectedTimeRange("daily");
                }
                break;
        }
    }

    setTimeRange(timeRanges);
    localStorage.setItem("selectedTimeRange", JSON.stringify(timeRanges));
    handleTimeRangeChange(timeRanges);
};

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "Last Week": [
      moment().subtract(1, "week").startOf("week"),
      moment().subtract(1, "week").endOf("week"),
    ],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 30 Days": [moment().subtract(29, "days"), today],
  };

  return (
    <div className="test" style={{ display: "inline" }}>
      <RBDaterangepicker
        startDate={startDate}
        endDate={endDate}
        ranges={ranges}
        onApply={handleApply}
        maxDate={today}
      >
        <span>
          <img src={CalanderIcon} alt="" />
          <input
            type="text"
            className="form-control text-center"
            value={`${startDate.format("MM/DD/YYYY")} - ${endDate.format(
              "MM/DD/YYYY"
            )}`}
            readOnly
          />
        </span>
      </RBDaterangepicker>
    </div>
  );
};

export default DateRangePicker;
