import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import countryList from "react-select-country-list";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { PaymentCompletionDialoge } from "./PaymentCompletionDialoge";
import SubscriptionDialog from "../SubscriptionDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { paymentCardExpiry } from "../../../../../_metronic/_partials/controls/forms/paymentCardExpiry";
import {
  getPaymentCard,
  savePaymentCard,
  verifyCompanyPaymentCard,
} from "../../../../../_redux/payment/paymentService";
import { useLocation } from "react-use";
import { useHistory } from "react-router-dom";
import { CompanyFetch } from "../../../../../_redux/company/companyActions";
import { i18n } from "../../../private/languageSelector";

const SubscriptionSchema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^[A-Za-z]+(?: [A-Za-z]+)+$/,
      i18n("SubscriptionModals.PleaseEnterAtLeastTwoNames")
    )
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  cardNumber: Yup.string()
    .matches(/^\d{16}$/, "16 number is required")
    .required("Card number is required"),
  expiry: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/([2-9][2-9])$/, "Invalid expiry date")
    .test("expiry", "Expiry date must be in the future", function(value) {
      if (!value) return false;

      const today = new Date();
      const [month, year] = value.split("/");
      const cardExpiry = new Date(`20${year}`, month - 1);

      return cardExpiry > today;
    })
    .required("Expiry date is required"),
  ccv: Yup.string()
    .matches(/^\d{3,4}$/, "Invalid CCV")
    .required("CCV is required"),
  country: Yup.string().required("Country is required"),
});

export function SubscriptionPaymentDialoge({
  showPaymentDialoge,
  setShowPaymentDialoge,
  companyInformationData,
  customUrl
}) {
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");
  const { companyForEdit } = useSelector(
    (state) => ({
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );
  const { paymentCard, getPaymentCardLoading } = useSelector(
    (state) => state.paymentCard
  );

  const { user } = useSelector((state) => state.auth);

  const [
    showPaymentCompletionDialoge,
    setShowPaymentCompletionDialoge,
  ] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [unExpectedCardIssues, setUnExpectedCardIssues] = useState(false);

  useEffect(() => {
    dispatch(CompanyFetch());
  }, [dispatch]);

  useEffect(() => {
    const fetchCard = async () => {
      if (companyForEdit._id) {
        try {
          if (companyForEdit?.paymentCards?.moyasar?.tempToken) {
            await dispatch(
              verifyCompanyPaymentCard({ companyId: companyForEdit._id })
            );
          }
          if (companyForEdit?.paymentCards?.moyasar?.token) {
            await dispatch(getPaymentCard({ companyId: companyForEdit._id }));
          }
        } catch (error) {
          console.error("Error fetching card:", error);
        }
      }
    };
    fetchCard();
  }, [companyForEdit._id, companyForEdit, dispatch, message]);

  useEffect(() => {
    if (
      message === "UNSPECIFIED_FAILURE" ||
      message === "INSUFFICIENT_FUNDS" ||
      message === "DECLINED" ||
      message ===
        "3-D Secure transaction attempt failed (DECLINED_INVALID_PIN)" ||
      message === "3-D Secure transaction attempt failed (Not Enrolled)"
    ) {
      setUnExpectedCardIssues(true);
    }
  }, [message]);

  const handleSubmit = async (values, { resetForm }) => {
    const paymentType = {
      name: values.name,
      number: values.cardNumber,
      cvc: values.ccv,
      month: values.expiry.split("/")[0],
      year: `20${values.expiry.split("/")[1]}`,
      callback_url: `${process.env.REACT_APP_MOYASAR_CALLBACK_URL}/${customUrl ? "openModal" : ""}`,
    };

    try {
      const response = await dispatch(
        savePaymentCard({
          companyId: companyInformationData._id,
          values: paymentType,
        })
      );

      const verificationUrl = response?.payload?.verificationUrl;
      if (verificationUrl) {
        window.location.href = verificationUrl;
      }
      resetForm();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClosePaymentDialog = (dirty) => {
    if (dirty) {
      setShowUnsavedModal(true);
    } else {
      setShowPaymentDialoge(false);
    }
  };

  const language = user && user.language === "arabic";

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          cardNumber: "",
          expiry: "",
          ccv: "",
          country: "SA",
        }}
        validationSchema={SubscriptionSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ handleSubmit, values, errors, touched, dirty, resetForm }) => {
          const isDisabled =
            !dirty ||
            !!errors.name ||
            !!errors.cardNumber ||
            !!errors.expiry ||
            !!errors.ccv ||
            !!errors.country;

          return (
            <Modal
              className={`${language && "ar"}  modal-drawer`}
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              show={showPaymentDialoge}
              onHide={() => handleClosePaymentDialog(dirty)}
            >
              <Modal.Header
                className="d-flex justify-content-around align-items-center"
                dir={language ? "rtl" : "ltr"}
              >
                <span onClick={() => handleClosePaymentDialog(dirty)}>
                  <img src={IconClose} alt="Close" />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "#FFFFFF",
                  }}
                >
                  {getPaymentCardLoading ? (
                    <span>Loading...</span>
                  ) : paymentCard ? (
                    i18n("Company.UPDATECARD")
                  ) : (
                    i18n("Company.ADDCARD")
                  )}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "18px",
                    fontWeight: "400",
                    color: isDisabled ? "#AAAAAA" : "#FFFFFF",
                    opacity: isDisabled ? 0.5 : 1,
                    pointerEvents: isDisabled ? "none" : "auto",
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {i18n("UserProfile.Save")}
                </span>
              </Modal.Header>

              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="form-group">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="cardNumber"
                      component={Input}
                      placeholder="Card Number"
                      label="Card Number"
                      maxLength={16}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="expiry"
                      component={paymentCardExpiry}
                      placeholder="MM/YY"
                      label="Expiry"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="ccv"
                      component={Input}
                      placeholder="CCV"
                      label="CCV"
                      maxLength={3}
                    />
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <Field as="select" name="country" className="form-control">
                      {options?.map((item, index) => (
                        <option key={index} value={item?.value}>
                          {item?.label}
                        </option>
                      ))}
                    </Field>
                    {touched.country && errors.country && (
                      <div className="text-danger">{errors.country}</div>
                    )}
                  </div>
                </Form>
              </Modal.Body>

              {/* unsaved modal */}
              <SubscriptionDialog
                modalWidth={"350px"}
                modalHeight={"185px"}
                modalBackgroundColor={"#FFFFFF"}
                show={showUnsavedModal}
                onHide={() => setShowUnsavedModal(false)}
                title={i18n("SubscriptionModals.UnsavedChanges")}
                titleWidth={"140px"}
                titleHeight={"20px"}
                titleFontWeight={"700"}
                titleFontSize={"16px"}
                titleLineHeight={"20px"}
                titleColor={"#333333"}
                message={i18n(
                  "SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges"
                )}
                messageWidth={"320px"}
                messageHeight={"44px"}
                messageFontSize={"16px"}
                messageFontWeight={"400"}
                messageLineHeight={"21.79px"}
                messageColor={"#333333"}
                primaryLabel={i18n("SubscriptionModals.CANCEL")}
                primaryAction={() => setShowUnsavedModal(false)}
                primaryLabelWidth={"55px"}
                primaryLabelHeight={"20px"}
                primaryLabelFontWeight={"700"}
                primaryLabelFontSize={"14px"}
                primaryLabelLineHeight={"20px"}
                primaryLabelColor={"#828282"}
                secondaryAction={() => {
                  setShowUnsavedModal(false);
                  setShowPaymentDialoge(false);
                  resetForm();
                }}
                secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                secondaryLabelWidth={"75px"}
                secondaryLabelHeight={"20px"}
                secondaryLabelFontWeight={"700"}
                secondaryLabelFontSize={"14px"}
                secondaryLabelLineHeight={"20px"}
                secondaryLabelColor={"#2D9CDB"}
              />
            </Modal>
          );
        }}
      </Formik>
      <PaymentCompletionDialoge
        showPaymentCompletionDialoge={showPaymentCompletionDialoge}
        setShowPaymentCompletionDialoge={setShowPaymentCompletionDialoge}
      />

      {/* payment declined modal */}
      <SubscriptionDialog
        modalWidth={"400px"}
        modalHeight={"175px"}
        modalBackgroundColor={"#FFFFFF"}
        show={unExpectedCardIssues}
        onHide={() => setUnExpectedCardIssues(false)}
        title={i18n("SubscriptionModals.PaymentDeclined")}
        titleWidth={"162px"}
        titleHeight={"29px"}
        titleFontWeight={"700"}
        titleFontSize={"18px"}
        titleLineHeight={"28.57px"}
        titleColor={"#333333"}
        message={i18n("SubscriptionModals.UnableToProcessPayment")}
        messageFontSize={"16px"}
        messageFontWeight={"400"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CLOSE")}
        primaryAction={() => {
          setUnExpectedCardIssues(false);
          history.push("/subscription");
        }}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelColor={"#2D9CDB"}
      />
    </>
  );
}
