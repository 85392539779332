import React, { useEffect, useState } from "react";
import { Layout } from "../_metronic/layout";
import { connect } from "react-redux";
import { Logout, AuthPage } from "./modules/Auth";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { Storage } from "react-jhipster";
import {
  FATORATAY_AUTH,
  shouldShowTrialExpiredModal,
} from "../helpers/constants";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import BasePage from "./BasePage";
import { TermsAndConditions } from "./modules/Auth/pages/TermsAndConditions";
import { UserRegistration } from "./pages/company/company-edit-Dialog/users/UserRegistration";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import VerifyAccount from "./modules/Auth/pages/verifyAccount";
import VerifyUserAccount from "../app/modules/Auth/pages/verifyUserAccount";
import ForgotPasswordNext from "../app/modules/Auth/pages/ForgotPasswordNext";
import PasswordReset from "../app/modules/Auth/pages/PasswordReset";
import PasswordResetNext from "../app/modules/Auth/pages/PasswordResetNext";
import RegistrationNext from "./modules/Auth/pages/RegistrationNext";
import UserOrCompany from "../app/modules/Auth/pages/userOrCompany";
import PdfInvoicePageHtml from "./pages/invoices/invoice-pdf/PdfInvoicePageHtml";
import InvoiceMobilePreview from "./pages/invoices/invoice-pdf/InvoiceMobilePreview";
import PdfInvoicePage from "./pages/invoices/invoice-pdf/pdfInvoicePage";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import HomeWrapper from "./pages/HomePage/HomeWrapper";
import SubscriptionDialog from "./pages/company/company-edit-Dialog/SubscriptionDialog";
import { i18n } from "./pages/private/languageSelector";
import PricingPlanDialog from "./pages/company/company-edit-Dialog/PricingPlanDialog";
import { SubscriptionPaymentDialoge } from "./pages/company/company-edit-Dialog/subscription/SubscriptionPaymentDialoge";
import { getCompany } from "../_redux/company/companyCrud";

const Routes = ({ requestUser }) => {
  const history = useHistory();

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
      authToken: auth.authToken,
      rememberMe: auth.rememberMe,
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const { paymentCard } = useSelector((state) => state.paymentCard);

  const { subscriptionData } = useSelector((state) => state.subscription);

  const { users } = useSelector((state) => ({
    users: state.users.entities,
  }));

  const [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [
    showContinueSubscriptionModal,
    setContinueSubscriptionModal,
  ] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showAddPaymentDailog, setShowAddPaymentDailog] = useState(false);
  const [companyInformationData, setCompanyInformationData] = useState(null);

  const fetchCompanyInformation = async () => {
    const response = await getCompany();
    setCompanyInformationData(response?.data?.company);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCompanyInformation();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const token =
      Storage.session.get(FATORATAY_AUTH) || Storage.local.get(FATORATAY_AUTH);
    if (token) {
      requestUser();
    }

    const trialExpiredModalShown =
      localStorage.getItem("trialExpiredModalShown") === "true";
    const subscribedClicked =
      localStorage.getItem("subscribedClicked") === "true";

    const shouldShowModal = shouldShowTrialExpiredModal(subscriptionData);

    if (
      !trialExpiredModalShown &&
      !subscribedClicked &&
      shouldShowModal &&
      window.location.pathname !== "/verify/account" &&
      window.location.pathname !== "/users/add"
    ) {
      setShowTrialExpiredModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData, users]);

  const handleSecondaryAction = () => {
    if (!paymentCard) {
      return setShowAddPaymentModal(true);
    }
    if (users && users?.length < 2) {
      setShowAddUserModal(true);
      setContinueSubscriptionModal(false);
      setShowTrialExpiredModal(false);
    } else if (users && users?.length >= 2) {
      setContinueSubscriptionModal(false);
      setShowPricingPlanModal(true);
      localStorage.removeItem("subscribedClicked");
      setShowTrialExpiredModal(false);
    }
  };

  const handleCancel = () => {
    setShowTrialExpiredModal(false);
    localStorage.setItem("trialExpiredModalShown", "true");
  };

  const handleSubscribe = () => {
    localStorage.setItem("subscribedClicked", "true");
    localStorage.setItem("trialExpiredModalShown", "true");
    handleSecondaryAction();
    setShowTrialExpiredModal(false);
  };

  const navigateUser = () => {
    history.push("/users/add");
    setShowAddUserModal(false);
  };

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          <Switch>
            <Route exact path="/homePage" component={HomeWrapper} />
            <Route path="/verify_account/:token">
              {({ history, match }) => (
                <VerifyAccount
                  show={match != null}
                  onHide={() => {
                    history.push("/");
                  }}
                />
              )}
            </Route>
            <Route path="/view/invoiceHtml/:id">
              {({ history, match }) => (
                <PdfInvoicePageHtml
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                    history.push("/invoices");
                  }}
                  view={true}
                  publicView={true}
                />
              )}
            </Route>
            <Route path="/posInvoice/:id">
              {({ history, match }) => (
                <PdfInvoicePageHtml
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                    history.push("/invoices");
                  }}
                  view={true}
                  publicView={true}
                />
              )}
            </Route>

            <Route path="/view/invoiceHtmlMobile/:id">
              {({ history, match }) => (
                <InvoiceMobilePreview
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                    history.push("/invoices");
                  }}
                  view={true}
                  publicView={true}
                />
              )}
            </Route>
            <Route path="/view/invoice/:id">
              {({ history, match }) => (
                <PdfInvoicePage
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                    history.push("/invoices");
                  }}
                  view={true}
                  publicView={true}
                />
              )}
            </Route>
            <Route
              exact
              path="/forgot-password-next"
              component={ForgotPasswordNext}
            />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route
              exact
              path="/password/reset/:token"
              component={PasswordReset}
            />
            <Route
              exact
              path="/password-reset-next"
              component={PasswordResetNext}
            />
            <Route exact path="/register-next" component={RegistrationNext} />
            <Route
              exact
              path="/setup-account/:token"
              component={UserRegistration}
            />
            <Route exact path="/en/privacy-policy" component={PrivacyPolicy} />
            <Route>
              <AuthPage />
            </Route>
          </Switch>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}
        <Route path="/view/invoice/:id">
          {({ history, match }) => (
            <PdfInvoicePage
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/invoices");
              }}
              view={true}
              publicView={true}
            />
          )}
        </Route>
        <Route path="/view/invoiceHtml/:id">
          {({ history, match }) => (
            <PdfInvoicePageHtml
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/invoices");
              }}
              view={true}
              publicView={true}
            />
          )}
        </Route>
        <Route path="/posInvoice/:id">
          {({ history, match }) => (
            <PdfInvoicePageHtml
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/invoices");
              }}
              view={true}
              publicView={true}
            />
          )}
        </Route>
        <Route path="/view/invoiceHtmlMobile/:id">
          {({ history, match }) => (
            <InvoiceMobilePreview
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/invoices");
              }}
              view={true}
              publicView={true}
            />
          )}
        </Route>
        <Route exact path="/homePage" component={HomeWrapper} />
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
        {!user?.accountVerified && (
          <Route path="/verify/account" component={VerifyUserAccount} />
        )}
        <Route path="/verify_account/:token">
          {({ history, match }) => (
            <VerifyAccount
              show={match != null}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route>
        <Route path="/user-or-company" component={UserOrCompany} />
        <Route exact path="/password/reset/:token" component={PasswordReset} />
        <Route
          exact
          path="/password-reset-next"
          component={PasswordResetNext}
        />
        <Route exact path="/register-next" component={RegistrationNext} />
        <Route
          exact
          path="/setup-account/:token"
          component={UserRegistration}
        />

        <Route exact path="/terms" component={TermsAndConditions} />
        <Route
          exact
          path="/forgot-password-next"
          component={ForgotPasswordNext}
        />
        <Route exact path="/en/privacy-policy" component={PrivacyPolicy} />
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Switch>
            <Redirect from="/auth" to="/" />
          </Switch>
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )}
      </Switch>

      {/* add payment modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddPaymentModal}
        onHide={() => setShowAddPaymentModal(false)}
        message={i18n(
          "SubscriptionModals.YouNeedToAddPaymentCardBeforeYouCanSubscribe"
        )}
        messageWidth={"260px"}
        messageHeight={"48px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddPaymentModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={() => {
          history.push("/subscription/add-payment-card");
          setShowAddPaymentDailog(true);
          setShowAddPaymentModal(false);
        }}
        secondaryLabel={i18n("Company.ADDCARD")}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* trial expired modal */}
      <SubscriptionDialog
        modalWidth={"350px"}
        title={i18n("SubscriptionModals.TrialExpired")}
        titleWidth={"100px"}
        titleHeight={"20px"}
        titleColor={"#333333"}
        titleFontWeight={700}
        titleFontSize={"14px"}
        titleLineHeight={"20px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showTrialExpiredModal}
        onHide={handleCancel}
        message={i18n(
          "SubscriptionModals.YourUserManagementTrialHasExpiredSubscribeNowToContinueUsingIt"
        )}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={handleCancel}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={handleSubscribe}
        secondaryLabel={i18n("global.SUBSCRIBE")}
        secondaryLabelWidth={"70px"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
        isTrialExpired={true}
      />

      {/* add user modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        message={
          subscriptionData?.subscription?.userTrial.isTrialStarted &&
          subscriptionData?.subscription?.userTrial.isTrialCancelled &&
          subscriptionData?.subscription?.userTrial.isTrialExpired
            ? i18n(
                "SubscriptionModals.YouNeedToCreateNewUserBeforeStartingSubscription"
              )
            : i18n(
                "SubscriptionModals.YouNeedToCreateNewUserBeforeStartingYourFreeTrial"
              )
        }
        messageWidth={"262px"}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddUserModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={navigateUser}
        secondaryLabel={i18n("SubscriptionModals.ADDUSER")}
        secondaryLabelWidth={"70px"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* continue subscription modal */}
      <SubscriptionDialog
        show={showContinueSubscriptionModal}
        onHide={() => setContinueSubscriptionModal(false)}
        modalWidth="300px"
        modalHeight="225px"
        title={i18n("SubscriptionModals.ContinueWithSubscription")}
        titleWidth="244px"
        titleHeight="29px"
        titleColor="#333333"
        titleFontWeight="700"
        titleFontSize="16px"
        titleLineHeight="20px"
        message={i18n(
          "SubscriptionModals.UserWasCreatedSuccessfullyYouCanNowContinueWithYourSubscription"
        )}
        messageWidth="260px"
        messageHeight="66px"
        messageColor="#333333"
        messageFontWeight="500"
        messageFontSize="16px"
        messageLineHeight="22px"
        primaryAction={() => setContinueSubscriptionModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="63px"
        primaryLabelHeight="16px"
        primaryLabelFontWeight="600"
        primaryLabelFontSize="16px"
        primaryLabelLineHeight="15.92px"
        primaryLabelColor="#828282"
        secondaryAction={() => history.push("/subscription/new")}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelWidth="86px"
        secondaryLabelHeight="16px"
        secondaryLabelFontWeight="600"
        secondaryLabelFontSize="16px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#2D9CDB"
      />

      <PricingPlanDialog
        showModal={showPricingPlanModal}
        onHide={() => setShowPricingPlanModal(false)}
      />

      <SubscriptionPaymentDialoge
        companyInformationData={companyInformationData}
        showPaymentDialoge={showAddPaymentDailog}
        setShowPaymentDialoge={setShowAddPaymentDailog}
        fetchCompanyInformation={fetchCompanyInformation}
      />
    </>
  );
};

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Routes);
