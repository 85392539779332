/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import CardIcon from "../../../assets/img/company/card.svg";
import { SubscriptionPaymentDialoge } from "../company/company-edit-Dialog/subscription/SubscriptionPaymentDialoge";
import { getCompany } from "../../../_redux/company/companyCrud";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getSubscription,
  getSubscriptionUsers,
  startFreeTrial,
  unSubscribeSubscription,
  unSubscribeTrial,
} from "../../../_redux/subscription/subscriptionService";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "../../../_redux/users/usersActions";
import SubscriptionDialog from "../company/company-edit-Dialog/SubscriptionDialog";
import moment from "moment/moment";
import {
  getPaymentCard,
  verifyCompanyPaymentCard,
} from "../../../_redux/payment/paymentService";
import { CompanyFetch } from "../../../_redux/company/companyActions";
import { useLocation } from "react-use";
import PricingPlanDialog from "../company/company-edit-Dialog/PricingPlanDialog";
import BootstrapTable from "react-bootstrap-table-next";
import pdfIcon from "../../../assets/img/company/PdfIcon.svg";
import html2canvas from "html2canvas";
import { i18n } from "../private/languageSelector";
import jsPDF from "jspdf";
import SubscriptionInvoices from "../invoices/subscription-invoices/SubscriptionInvoice";
import {
  getSubscriptionInvoice,
  getSubscriptionInvoices,
} from "../../../_redux/subscriptionInvoices/SubscriptionInvoicesService";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";
import creditIcon from "../../../assets/img/company/credit.svg";
import ErrorIcon from "@material-ui/icons/Error";
import PageNavigator from "../../components/pagination/PageNavigator";
import {
  formatNumber,
  shouldRedirectToAddUser,
} from "../../../helpers/constants";

const Subscription = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const rxPrintRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");

  const { subscriptionData } = useSelector((state) => state.subscription);
  const { subscriptionUsers } = useSelector((state) => state.subscription);

  const { invoices } = useSelector((state) => state.subscriptionInvoices);
  const { companyForEdit } = useSelector(
    (state) => ({
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );
  const { language } = useSelector((state) => state.auth);

  const { taxNumber, companyName, country } = companyForEdit;

  const billedTo = {
    taxNumber: taxNumber,
    companyName: companyName,
    country: country,
  };

  const { paymentCard, getPaymentCardLoading } = useSelector(
    (state) => state.paymentCard
  );
  const { users } = useSelector((state) => ({
    users: state.users.entities,
  }));

  const [showAddPaymentDailog, setShowAddPaymentDailog] = useState(false);
  const [companyInformationData, setCompanyInformationData] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [
    showContinueSubscriptionModal,
    setContinueSubscriptionModal,
  ] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [customUrl, setCustomUrl] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      await dispatch(getSubscriptionInvoices({ page, limit }));
    };
    fetchInvoices();
  }, [page, limit]);

  const individualAccountUsers =
    users && users?.filter((user) => user.rolePayload.name !== "owner");

  const fetchCompanyInformation = async () => {
    const response = await getCompany();
    setCompanyInformationData(response?.data?.company);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchUsers());
      await fetchCompanyInformation();
      setTimeout(() => {
        dispatch(getSubscription());
      }, 1000);
      await dispatch(CompanyFetch());
      await dispatch(getSubscriptionUsers());
      const response = await getUserByToken();
      setLoginUser(response?.data);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/subscription/add-payment-card") {
      setShowAddPaymentDailog(true);
    }
  }, [location.pathname, subscriptionData]);

  useEffect(() => {
    const fetchCard = async () => {
      if (companyForEdit?._id) {
        setIsLoading(true);
        try {
          if (companyForEdit?.paymentCards?.moyasar) {
            await dispatch(
              verifyCompanyPaymentCard({ companyId: companyForEdit._id })
            );
          }
          if (companyForEdit?.paymentCards?.moyasar) {
            await dispatch(getPaymentCard({ companyId: companyForEdit._id }));
          }
        } catch (error) {
          console.error("Error fetching card:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchCard();
  }, [companyForEdit?._id, dispatch]);

  useEffect(() => {
    if (message === "APPROVED") {
      if (!localStorage.getItem("pageReloaded")) {
        localStorage.setItem("pageReloaded", true);
        window.location.reload();
      }
      history.push("/subscription");
      if (
        location.pathname.includes("/subscription/openModal") ||
        localStorage.getItem("subscribedClicked")
      ) {
        setContinueSubscriptionModal(true);
      }
    }
  }, [message, history]);

  useEffect(() => {
    if (location.pathname === "/subscription/new") {
      setShowPricingPlanModal(true);
    }
  }, [location.pathname]);

  const startFreeTrialHandler = async () => {
    if (users.length < 2) {
      setShowAddUserModal(true);
    } else {
      await dispatch(startFreeTrial());
      await dispatch(getSubscription());
    }
  };

  const navigateUser = () => {
    if (loginUser && !loginUser?.posPin) {
      history.push("/user/setup-owner-pos-pin");
    } else {
      history.push("/users/add");
    }
  };

  const handleSubscription = () => {
    if (!paymentCard) {
      setShowAddPaymentModal(true);
      setCustomUrl(true);
    } else if (paymentCard && users && users?.length >= 2) {
      localStorage.setItem("subscribedClicked", "true");
      setShowPricingPlanModal(true);
    } else if (paymentCard && users && users?.length < 2) {
      setShowAddUserModal(true);
      localStorage.setItem("subscribedClicked", "true");
    }
  };

  const handleSecondaryAction = () => {
    if (shouldRedirectToAddUser(subscriptionData)) {
      history.push("/users/add");
    }
    if (users && users?.length < 2) {
      setShowAddUserModal(true);
      setContinueSubscriptionModal(false);
    } else if (users && users?.length >= 2) {
      setContinueSubscriptionModal(false);
      setShowPricingPlanModal(true);
      localStorage.removeItem("subscribedClicked");
    }
  };

  const handleUnsubscription = async () => {
    if (subscriptionData?.subscription?.subscriptionInfo?.isSubscribed) {
      await dispatch(unSubscribeSubscription());
    } else {
      await dispatch(unSubscribeTrial());
    }
    await dispatch(getSubscription());
    await dispatch(fetchUsers());
    setShowUnsubscribeModal(false);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const renderSubscriptionInfo = () => {
    const isFreePlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
      subscriptionData?.subscription?.userTrial?.isTrialStarted === false &&
      subscriptionData?.subscription?.userTrial?.isTrialCancelled === false &&
      subscriptionData?.subscription?.userTrial?.isTrialExpired === false;

    const isTrialPlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
      subscriptionData?.subscription?.userTrial?.isTrialStarted === true;

    const isSubscribed =
      subscriptionData?.subscription?.subscriptionInfo?.isSubscribed;

    const trialEndedAt = subscriptionData?.subscription?.trialEndedAt;

    const subscriptionEndedAt =
      subscriptionData?.subscription?.subscriptionEndedAt;

    const individualUsers =
      users?.filter((user) => user.rolePayload.name !== "owner") || [];

    const currentPlanName = subscriptionData?.subscription?.currentPlan?.plan;

    const userCount = individualUsers.length;

    const perUserPrice = currentPlanName === "Monthly" ? 19.0 : 190.0;

    const currentPlanPriceUpdated =
      userCount > 1 ? perUserPrice * userCount : perUserPrice;

    return (
      <>
        <div>
          {(isFreePlan || isTrialPlan) && (
            <p
              className={
                isFreePlan ? "try-for-30-days" : "trial-expiration-date"
              }
              style={
                isTrialPlan
                  ? {
                      display: "flex",
                      gap: "4px",
                      flexDirection: language === "arabic" ? "row" : "inherit",
                      color: subscriptionData?.subscription?.userTrial
                        ?.isTrialExpired
                        ? "#EB5757"
                        : "#F2994A",
                    }
                  : {}
              }
            >
              {isFreePlan
                ? i18n("Company.Try30DayFreeTrial")
                : `${i18n("Company.TrialExpirationDate")} ${moment(
                    trialEndedAt
                  ).format("MMM DD, YYYY")}`}
            </p>
          )}
          {isSubscribed && (
            <div className="plan-price-users-next-bill-date">
              {language === "arabic" ? (
                <span className="plan-price" style={{ textAlign: "right" }}>
                  {currentPlanPriceUpdated}.00 {i18n("SubscriptionModals.SAR")}{" "}
                  {currentPlanName === "Monthly"
                    ? i18n("global.Monthly")
                    : i18n("global.Yearly")}
                </span>
              ) : (
                <span className="plan-price">
                  SAR {currentPlanPriceUpdated}.00 {currentPlanName}
                </span>
              )}
              {language === "arabic" ? (
                <span
                  className="plan-price-users"
                  style={{ textAlign: "right" }}
                >
                  <span>
                    {individualAccountUsers && individualAccountUsers.length}{" "}
                  </span>
                  <span>{i18n("SubscriptionModals.Users")}</span>{" "}
                  <span style={{ visibility: "hidden" }}>x</span>
                  <span>
                    {currentPlanName === "Monthly" ? "19.00" : "190.00"}
                  </span>{" "}
                  x<span>{i18n("SubscriptionModals.SAR")}</span>{" "}
                  <span>
                    {subscriptionData?.subscription?.currentPlan?.plan ===
                    "Monthly"
                      ? i18n("SubscriptionModals.PerUserPerMonth")
                      : i18n("SubscriptionModals.PerUserPerYear")}
                  </span>{" "}
                </span>
              ) : (
                <span className="plan-price-users">
                  {subscriptionUsers && subscriptionUsers.length} Users x SAR{" "}
                  {currentPlanName === "Monthly" ? "19.00" : "190.00"}{" "}
                  <span>
                    {subscriptionData?.subscription?.currentPlan?.plan ===
                    "Monthly"
                      ? i18n("SubscriptionModals.PerUserPerMonth")
                      : i18n("SubscriptionModals.PerUserPerYear")}
                  </span>{" "}
                </span>
              )}
              {language === "arabic" ? (
                <span className="next-bill-date" style={{ textAlign: "right" }}>
                  {i18n("SubscriptionModals.NextBillingDate")}{" "}
                  {moment(subscriptionEndedAt).format("MMM DD, YYYY")}
                </span>
              ) : (
                <span className="next-bill-date">
                  Next billing date:{" "}
                  {moment(subscriptionEndedAt).format("MMM DD, YYYY")}
                </span>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderActionButtons = () => {
    const isFreePlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
      subscriptionData?.subscription?.userTrial?.isTrialStarted === false &&
      subscriptionData?.subscription?.userTrial?.isTrialCancelled === false &&
      subscriptionData?.subscription?.userTrial?.isTrialExpired === false;

    const isTrialPlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
      subscriptionData?.subscription?.userTrial?.isTrialStarted === true;

    const isSubscribed =
      subscriptionData?.subscription?.subscriptionInfo?.isSubscribed;

    const isTrialExpired =
      isTrialPlan &&
      subscriptionData?.subscription?.currentPlan?.status === "Expired" &&
      subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

    const trialExpiredAndFreePlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
      subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

    const isUnsubscribedSubscription =
      subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
      subscriptionData?.subscription?.subscriptionInfo?.isUnsubscribed === true;

    const isUserConvertFromSubscriptionToFreePlan =
      subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
      subscriptionData?.subscription?.currentPlan?.status === "Running" &&
      subscriptionData?.subscription?.userTrial?.isTrialStarted &&
      subscriptionData?.subscription?.userTrial?.isTrialCancelled;

    return (
      <div>
        {isFreePlan && (
          <p
            className="try-for-free"
            onClick={() => {
              localStorage.removeItem("subscribedClicked");
              startFreeTrialHandler();
            }}
          >
            {i18n("Company.TRYFORFREE")}
          </p>
        )}
        <div className="d-flex justify-content-between">
          {isTrialExpired && (
            <p
              onClick={() => setShowUnsubscribeModal(true)}
              className="unsubscribe-btn mr-4"
            >
              {i18n("Company.BACKTOFREEPLAN")}
            </p>
          )}
          {(isTrialPlan ||
            trialExpiredAndFreePlan ||
            isUnsubscribedSubscription ||
            isUserConvertFromSubscriptionToFreePlan) && (
            <p onClick={handleSubscription} className="try-for-free">
              {i18n("global.SUBSCRIBE")}
            </p>
          )}
        </div>
        {isSubscribed && (
          <div>
            <p
              onClick={() => setShowPricingPlanModal(true)}
              className="try-for-free"
            >
              {i18n("SubscriptionModals.CHANGEPLAN")}
            </p>
            <p
              onClick={() => setShowUnsubscribeModal(true)}
              className="try-for-free pt-4"
            >
              {i18n("SubscriptionModals.UNSUBSCRIBE")}
            </p>
          </div>
        )}
      </div>
    );
  };

  const handleGeneratePDF = async (invoiceId) => {
    setShowInvoice(true);

    try {
      setTimeout(async () => {
        if (invoiceId) {
          const response = await dispatch(
            getSubscriptionInvoice({ invoiceId: invoiceId })
          );
          setInvoice(response?.payload?.invoice);
        }
        const input = document.getElementById("divToPrint");

        if (input) {
          const pdfWidth = input.clientWidth;
          const pdfHeight = input.clientHeight;
          const dpi = 300;

          html2canvas(input, {
            scale: dpi / 90,
            width: pdfWidth,
            height: pdfHeight,
            logging: true,
          }).then((canvas) => {
            setShowInvoice(false);
            const imgData = canvas.toDataURL("image/png", 1.0);

            const pdf = new jsPDF({
              unit: "px",
              format: [pdfWidth, pdfHeight],
            });

            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            const blobURL = pdf.output("bloburl");

            window.open(blobURL, "_blank");
          });
        }
      }, 1000);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setShowInvoice(false);
    }
  };

  const filterInvoices =
    invoices?.invoices &&
    invoices?.invoices?.filter(
      (invoice) => invoice.paymentMetaData.invoiceType === "sale"
    );

  const invoicesData =
    filterInvoices &&
    filterInvoices?.map((invoice) => {
      return {
        ...invoice,
        ...invoice?.paymentMetaData,
        createdAt: moment(invoice?.createdAt).format("MMM D, YYYY"),
      };
    });

  const columns = [
    {
      dataField: "createdAt",
      text: i18n("SubscriptionModals.Date"),
      headerAlign: language === "arabic" ? "right" : "left",
      headerStyle: { textAlign: language === "arabic" ? "right" : "left" },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
    },

    {
      dataField: i18n("SubscriptionModals.UserManagement"),
      text: i18n("SubscriptionModals.Service"),
      headerAlign: language === "arabic" ? "right" : "left",
      headerStyle: {
        textAlign: language === "arabic" ? "right" : "left",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      formatter: (cell, row) =>
        `${i18n("SubscriptionModals.UserManagement")} (${
          row?.paymentMetaData?.seats
        })`,
    },
    {
      dataField: "amount",
      text: i18n("SubscriptionModals.Amount"),
      headerAlign: language === "arabic" ? "right" : "left",
      headerStyle: {
        textAlign: language === "arabic" ? "right" : "left",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      align: language === "arabic" ? "right" : "left",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      formatter: (cell, row) =>
        `${
          row?.paymentMetaData?.totalPaid >= 0
            ? `${i18n("SubscriptionModals.SAR")} ${formatNumber(
                row?.paymentMetaData?.totalPaid
              )}`
            : `${i18n("SubscriptionModals.SAR")} ${0.0}`
        }`,
    },
    {
      dataField: "",
      text: "",
      headerAlign: "end",
      headerStyle: {
        textAlign: language === "arabic" ? "left" : "right",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      align: "end",
      style: {
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: language === "arabic" ? "50px" : "0px",
        paddingLeft: language !== "arabic" ? "50px" : "0px",
      },
      formatter: (cell, row) => (
        <img
          onClick={() => handleGeneratePDF(row._id)}
          src={pdfIcon}
          alt="PDF Icon"
          style={{ width: "20px", height: "20px" }}
        />
      ),
    },
  ];

  const isUserConvertFromSubscriptionToFreePlan =
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.userTrial?.isTrialStarted &&
    subscriptionData?.subscription?.userTrial?.isTrialCancelled;

  const isTrialCancelled =
    subscriptionData?.subscription?.currentPlan?.plan === "Free" &&
    subscriptionData?.subscription?.currentPlan?.status === "Running" &&
    subscriptionData?.subscription?.userTrial?.isTrialStarted &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired;

  const creditFormatted = formatNumber(subscriptionData?.subscription?.credit);

  return (
    <>
      <div
        className="row d-flex justify-content-between"
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
        }}
      >
        <div className="col-lg-6 col-md-12 d-flex flex-column">
          {subscriptionData?.subscription?.currentPlan?.status === "Paused" && (
            <div className="card border border-danger">
              <div className="d-flex justify-content-between align-items-center">
                <p className="mt-2 d-flex justify-content-center">
                  <span className="mr-1">
                    <ErrorIcon color="error" />
                  </span>
                  <span>
                    {i18n(
                      "SubscriptionModals.SubscriptionAutoRenewalFailedEnsurePayamentCardHasSufficientFunds"
                    )}
                  </span>
                </p>
                <p className="mt-2 try-for-free">
                  {i18n("SubscriptionModals.RETRYPAYMENT")}
                </p>
              </div>
            </div>
          )}

          <div
            className="card"
            style={{
              height:
                (isUserConvertFromSubscriptionToFreePlan || isTrialCancelled) &&
                "110px",
            }}
          >
            <div className="card-title d-flex flex-column">
              <span
                className="users-managment"
                style={{
                  textAlign: language === "arabic" ? "right" : "left",
                }}
              >
                {i18n("Company.UsersManagement")}
              </span>
              <span
                className="mt-2 manage-access-rights"
                style={{
                  textAlign: language === "arabic" ? "right" : "left",
                }}
              >
                {i18n("Company.ManageAccessRightsSalesByUser")}
              </span>
            </div>
            <div className="flex-row d-flex justify-content-between align-items-center">
              <div>{renderSubscriptionInfo()}</div>
              <div
                style={{
                  marginTop:
                    (isUserConvertFromSubscriptionToFreePlan ||
                      isTrialCancelled) &&
                    "-55px",
                }}
              >
                {renderActionButtons()}
              </div>
            </div>
          </div>
          <div className="card" style={{ backgroundColor: "#FFFFFF" }}>
            <div
              className="card-title"
              style={{
                textAlign: language === "arabic" ? "right" : "left",
              }}
            >
              <span className="payment-card">
                {i18n("Company.PaymentCard")}
              </span>
            </div>
            <div className="flex-row d-flex justify-content-between">
              <p
                className="try-for-30-days"
                style={{
                  direction: language === "arabic" ? "rtl" : "ltr",
                  textAlign: language === "arabic" ? "right" : "left",
                }}
              >
                <img
                  src={CardIcon}
                  alt="CardIconImage"
                  className={`${
                    language === "arabic" ? "float-right" : "float-left"
                  }`}
                />
                {getPaymentCardLoading || isLoading ? (
                  <span className="ml-2 available-credit-note">Loading...</span>
                ) : paymentCard?.status === false ? (
                  <span className="mr-4 ml-4">
                    {i18n("Company.CreditCardNotYetAdded")}
                  </span>
                ) : paymentCard ? (
                  <>
                    <span
                      className={`ml-4 mr-4 available-credit-note ${
                        language === "arabic" ? "float-right" : "float-left"
                      }`}
                    >
                      {paymentCard?.brand} ****{paymentCard?.last_four}
                    </span>
                    <span
                      className={`ml-12 mr-12 available-credit-note ${
                        language === "arabic" ? "float-right" : "float-left"
                      }`}
                    >
                      {paymentCard?.month}/{paymentCard?.year}
                    </span>
                  </>
                ) : (
                  <span className="mr-4 ml-4">
                    {i18n("Company.CreditCardNotYetAdded")}
                  </span>
                )}
              </p>
              <p
                className="add-payment-card"
                onClick={() => {
                  localStorage.removeItem("subscribedClicked");
                  setShowAddPaymentDailog(true);
                }}
              >
                {getPaymentCardLoading || isLoading ? (
                  <span className="available-credit-note"> </span>
                ) : paymentCard?.status === false ? (
                  <span>{i18n("Company.ADDCARD")}</span>
                ) : paymentCard ? (
                  <span>{i18n("Company.UPDATECARD")}</span>
                ) : (
                  <span>{i18n("Company.ADDCARD")}</span>
                )}
              </p>
            </div>
            {subscriptionData?.subscription?.credit > 0 && (
              <div className="d-flex align-items-center">
                <img src={creditIcon} alt="credit" />
                {language === "arabic" ? (
                  <p className="mt-3 ml-4 available-credit-note">
                    {i18n("SubscriptionModals.AvailableCredit")}:{" "}
                    {creditFormatted} {i18n("SubscriptionModals.SAR")}
                  </p>
                ) : (
                  <p className="mt-3 ml-4 available-credit-note">
                    {i18n("SubscriptionModals.AvailableCredit")}:{" "}
                    {i18n("SubscriptionModals.SAR")} {creditFormatted}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {invoices && invoices?.invoices?.length > 0 && (
          <div className="col-lg-6 col-md-12">
            <div className="card">
              <p className="invoice-heading">{i18n("DASHBOARD.Invoices")}</p>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                remote
                bordered={false}
                bootstrap4
                keyField="_id"
                columns={columns}
                data={invoicesData ? invoicesData : []}
                rowClasses="cursor-pointer"
                rowEvents={{
                  onClick: (e, row) => {},
                }}
              />
              <div className="pb-3 d-flex justify-content-end mr-3 ml-3">
                <PageNavigator
                  page={page}
                  limit={limit}
                  handlePageChange={handlePageChange}
                  handleLimitChange={handleLimitChange}
                  data={invoicesData}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <SubscriptionPaymentDialoge
        companyInformationData={companyInformationData}
        showPaymentDialoge={showAddPaymentDailog}
        setShowPaymentDialoge={setShowAddPaymentDailog}
        fetchCompanyInformation={fetchCompanyInformation}
        customUrl={customUrl}
      />

      {/* add user modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
        message={
          subscriptionData?.subscription?.userTrial.isTrialStarted &&
          subscriptionData?.subscription?.userTrial.isTrialCancelled &&
          subscriptionData?.subscription?.userTrial.isTrialExpired
            ? i18n(
                "SubscriptionModals.YouNeedToCreateNewUserBeforeStartingSubscription"
              )
            : i18n(
                "SubscriptionModals.YouNeedToCreateNewUserBeforeStartingYourFreeTrial"
              )
        }
        messageWidth={"262px"}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddUserModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={navigateUser}
        secondaryLabel={i18n("SubscriptionModals.ADDUSER")}
        secondaryLabelWidth={"70px"}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* add payment modal */}
      <SubscriptionDialog
        modalWidth={"300px"}
        modalHeight={"150px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showAddPaymentModal}
        onHide={() => setShowAddPaymentModal(false)}
        message={i18n(
          "SubscriptionModals.YouNeedToAddPaymentCardBeforeYouCanSubscribe"
        )}
        messageWidth={"260px"}
        messageHeight={"48px"}
        messageFontSize={"16px"}
        messageFontWeight={"500"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowAddPaymentModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={() => {
          setShowAddPaymentDailog(true);
          setShowAddPaymentModal(false);
        }}
        secondaryLabel={i18n("Company.ADDCARD")}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* unsubscribe modal */}
      <SubscriptionDialog
        modalWidth={"400px"}
        modalHeight={"200px"}
        title={i18n("SubscriptionModals.UnsubscribeFromUsersManagement")}
        titleHeight={"29px"}
        titleFontWeight={"700"}
        titleColor={"#333333"}
        titleFontSize={"18px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showUnsubscribeModal}
        onHide={() => setShowUnsubscribeModal(false)}
        message={i18n(
          "SubscriptionModals.UnsubscribeSubscriptionWillResultRemovalAllUsers"
        )}
        messageHeight={"60px"}
        messageFontSize={"14px"}
        messageFontWeight={"400"}
        messageLineHeight={"20px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowUnsubscribeModal(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"30px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"29.57px"}
        primaryLabelColor={"#828282"}
        secondaryAction={handleUnsubscription}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelHeight={"30px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"29.57px"}
        secondaryLabelColor={"#2D9CDB"}
      />

      {/* continue subscription modal */}
      <SubscriptionDialog
        show={showContinueSubscriptionModal}
        onHide={() => setContinueSubscriptionModal(false)}
        modalWidth="300px"
        modalHeight="225px"
        title={i18n("SubscriptionModals.ContinueWithSubscription")}
        titleWidth="244px"
        titleHeight="29px"
        titleColor="#333333"
        titleFontWeight="700"
        titleFontSize="16px"
        titleLineHeight="20px"
        message={i18n(
          "SubscriptionModals.PaymentCardAddedSuccessfullyYouCanNowContinueWithAddingUsers"
        )}
        messageWidth="260px"
        messageHeight="66px"
        messageColor="#333333"
        messageFontWeight="500"
        messageFontSize="16px"
        messageLineHeight="22px"
        primaryAction={() => setContinueSubscriptionModal(false)}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryLabelWidth="63px"
        primaryLabelHeight="16px"
        primaryLabelFontWeight="600"
        primaryLabelFontSize="16px"
        primaryLabelLineHeight="15.92px"
        primaryLabelColor="#828282"
        secondaryAction={handleSecondaryAction}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelWidth="86px"
        secondaryLabelHeight="16px"
        secondaryLabelFontWeight="600"
        secondaryLabelFontSize="16px"
        secondaryLabelLineHeight="20px"
        secondaryLabelColor="#2D9CDB"
      />

      {/* select plan modal */}
      <PricingPlanDialog
        showModal={showPricingPlanModal}
        onHide={() => setShowPricingPlanModal(false)}
      />

      <div style={{ opacity: "0" }}>
        {showInvoice ? (
          <SubscriptionInvoices
            rxPrintRef={rxPrintRef}
            language={language}
            invoice={invoice}
            paymentCardLastFourDigit={paymentCard?.last_four}
            billedTo={billedTo}
          />
        ) : null}
      </div>
    </>
  );
};

export default Subscription;
