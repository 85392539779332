import React from "react";
import LogoOne from "../../../../assets/img/company/Logo1.svg";
import LogoTwo from "../../../../assets/img/company/Logo2.svg";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import { formatNumber } from "../../../../helpers/constants";

const SubscriptionArabicInvoice = ({
  rxPrintRef,
  invoice,
  paymentCardLastFourDigit,
  billedTo,
}) => {
  if (!invoice) {
    return null;
  }

  const formattedDate = moment(invoice?.created_at).format("MMM D, YYYY");

  const formattedDateWithTime = moment(invoice?.updated_at).format(
    "D MMM, YYYY HH:mm"
  );

  const invoiceType = invoice?.paymentMetaData?.planType;

  const planType = invoiceType?.split(" ")[0];

  const seats = invoice?.paymentMetaData?.seats || 0;

  const basePrice = invoice?.paymentMetaData?.basePrice || 0;

  const subtotal = seats * basePrice;

  const appliedBalance =
    Math.min(invoice?.paymentMetaData?.creditUsed, subtotal) || 0;

  const amountBeforeTax = Math.abs(subtotal - appliedBalance);

  let tax = amountBeforeTax === 0 ? 0 : invoice?.paymentMetaData?.tax;

  let totalWithTax = amountBeforeTax === 0 ? 0 : amountBeforeTax + tax;

  const paymentAmount = totalWithTax > 0 ? totalWithTax : 0;

  const amountDue = 0;

  const invoiceDateFrom = moment(invoice?.billing_cycle?.from).format(
    "MMM D, YYYY"
  );
  const invoiceDateTo = moment(invoice?.billing_cycle?.till).format(
    "MMM D, YYYY"
  );

  return (
    <div className="invoice-container-arabic" ref={rxPrintRef}>
      <div className="invoice" id="divToPrint">
        <div className="invoice-header">
          <div className="invoice-logos">
            <img src={LogoOne} alt="الصورة" />
            <img src={LogoTwo} alt="الصورة" />
          </div>
          <div className="invoice-title">
            <span>فاتورة ضريبية</span>
          </div>
        </div>

        <div className="invoice-info">
          <div className="company-info">
            <span>مؤسسة نمو لتقنية المعلومات - فاتورتي</span>
            <span>الدمام - المملكة العربية السعودية</span>
            <span>سجل تجاري: 2050139742</span>
            <span>الرقم الضريبي:</span>
          </div>
          <div className="invoice-details">
            <span>رقم الفاتورة: A1- {invoice?.invoiceNumber}</span>
            <span>تاريخ: {formattedDate}</span>
            <span>تاريخ التوريد: {formattedDate}</span>
            <span>رقم العميل: 1000005</span>
            <span className="status-paid">{invoice?.status}</span>
          </div>
        </div>

        <div className="billing-info">
          <div className="billed-to">
            <span className="title">مفوتر إلى:</span>
            <span>{billedTo?.companyName}</span>
            <span>الرقم الضريبي: {billedTo?.taxNumber}</span>
            <span>{billedTo?.country}</span>
          </div>
          <div className="subscription-info">
            <span className="title">الاشتراك:</span>
            <span>المعرَف: QzqKjkR2pnkMr1Syu</span>
            <span>
              فترة الفوترة: {invoiceDateFrom} إلى {invoiceDateTo}
            </span>
            <span>تاريخ الفوترة التالي: {invoiceDateTo}</span>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th className="description" style={{ textAlign: "right" }}>
                الوصف
              </th>
              <th className="units" style={{ textAlign: "right" }}>
                الوحدات
              </th>
              <th className="unit-price">سعر الوحدة</th>
              <th className="amount">المبلغ ر.س</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="description" style={{ textAlign: "right" }}>
                إدارة المستخدمين - {planType}
              </td>
              <td className="units" style={{ textAlign: "right" }}>
                {seats} مستخدم
              </td>
              <td className="unit-price">ر.س {formatNumber(basePrice)}</td>
              <td className="amount">ر.س {formatNumber(subtotal)}</td>
            </tr>
          </tbody>
        </table>

        <div className="total-info-arabic">
          <div className="totals">
            {appliedBalance > 0 && (
              <span className="title">المجموع الفرعي</span>
            )}
            {appliedBalance > 0 && <span>الرصيد المطبق</span>}
            <span className="title">المجموع قبل الضريبة</span>
            <span>ضريبة القيمة المضافة 15٪</span>
            <span className="title">المجموع بعد الضريبة</span>
            {paymentAmount > 0 && <span>المدفوعات</span>}
            <span className="title amount-due">المبلغ المستحق</span>
          </div>
          <div className="amounts">
            {appliedBalance > 0 && (
              <span className="title">ر.س {formatNumber(subtotal)}</span>
            )}
            {appliedBalance > 0 && (
              <span>(ر.س {formatNumber(appliedBalance)})</span>
            )}
            <span className="title">ر.س {formatNumber(amountBeforeTax)}</span>
            <span>ر.س {formatNumber(tax)}</span>
            <span className="title">ر.س {formatNumber(totalWithTax)}</span>
            {paymentAmount > 0 && (
              <span>(ر.س {formatNumber(paymentAmount)})</span>
            )}
            <span className="title amount-due">
              ر.س {formatNumber(amountDue)}
            </span>
          </div>
        </div>

        <div className="credits-info" style={{ textAlign: "right" }}>
          <p className="credits-title">الأرصدة و المدفوعات</p>
          {appliedBalance > 0 && (
            <p>
              تم تطبيق رصيد بقيمة{" "}
              <span className="title">ر.س {formatNumber(appliedBalance)}</span>{" "}
              في {formattedDateWithTime} بتوقيت السعودية.
            </p>
          )}
          {paymentAmount > 0 && (
            <p>
              تم دفع{" "}
              <span className="title">ر.س {formatNumber(paymentAmount)}</span>{" "}
              في {formattedDateWithTime} بتوقيت السعودية عن طريق بطاقة Visa card
              المنتهية بالرقم {paymentCardLastFourDigit}.
            </p>
          )}
        </div>
        <div className="invoice-qr-container">
          <QRCodeSVG
            value={
              "مؤسسة نمو لتقنية المعلومات - فاتورتي, الدمام - المملكة العربية السعودية, سجل تجاري: 2050139742"
            }
            size={100}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionArabicInvoice;
